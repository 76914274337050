import * as React from 'react';
import Layout, { cyanButtonStyle } from '../components/layout';
import { Seo } from "../components/seo"
import { BookOne, BookTwo, BookThree, keywords } from "../components/bookinfo";
import MailSignup from '../components/mailsignup';

export const Head = () => (
  <Seo 
    title="Source's Edge"
    description="a science fantasy adventure series by Ryan Sivek"
    pathname={"/sourcesedge"}
    keywords={keywords}
    type="book"
  />
)

const SEPage = () => {
  const [mailSignupOpen, setMailSignupOpen] = React.useState(false);

  function toggleMailSignup() {
    setMailSignupOpen(!mailSignupOpen);
  }

  return (
    <Layout pagePath="/sourcesedge">
      <MailSignup isOpen={mailSignupOpen} onClose={toggleMailSignup}/>
      <div className="container mx-auto px-4 text-center">
        <h1 className="hidden">Source's Edge</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
          <BookOne/>
          <BookTwo/>
          <BookThree/>
        </div>
        <button className={cyanButtonStyle} onClick={toggleMailSignup}>Want to get updates on my work? Click here!</button>
      </div> 
    </Layout>
  )
};

export default SEPage;
